var messages = {
  privacy: {
    title: " نصائح استخدام خدمة فانشين ",
    text1: " شكرًا لك على ثقتك، مرحبًا بك باستخدام فانشين!",
    text2:
      " من أجل حماية حقوقك ومصالحك بشكل أفضل، يُرجى قراءة ما يلي بعناية وفهمه بالكامل ",
    text3: " اتفاقية خدمة المستخدم الفردي وسياسة الخصوصية.",
    text4:
      " إذا قمت بالنقر فوق ”موافق“، فهذا يعني أنك قد قرأت وفهمت وقبلت جميع محتويات هذه الاتفاقية بالكامل، وستكون هذه الاتفاقية ملزمة قانونًا. إذا قمت بالنقر فوق ”غير موافق“، يمكنك الخروج من هذه الصفحة."
  },
  meta: {
    homePage: "الصفحة الرئيسية",
    userPage: "أنا",
    productDetails: "تفاصيل المنتج",
    store: "المتجر",
    userManagement: " إدارة المستخدم ",
    attentionGoods: " الإعجابات والمقتنيات ",
    attentionShop: " متابعة المتاجر ",
    browsingHistory: " تصفح التواريخ ",
    userSet: "الإعدادات",
    declaration: " إعلان ",
    orderdetail: " تفاصيل الطلب ",
    search: " البحث عن منتج ",
    special: " المنطقة الساخنة ",
    editAddress: " تعديل العنوان ",
    shippingAddress: " إعدادات عنوان التوصيل ",
    addAddress: "إضافة عنوان",
    allProducts: "كل المنتجات",
    order: "رقم طلب الشراء الخاص بي",
    newsCenter: " مركز الأخبار ",
    newArrival: " وصل حديثاً ",
    morePopular: " الأكثر مبيعاً ",
    authentication: " المصادقة ",
    videoCollection: " مجموعة الفيديو ",
    more: "المزيد",
    Agreement: " اتفاقية خدمة المستخدم ",
    privacy: " سياسة الخصوصية ",
    agree: "موافق",
    disagree: "غير موافق",
    submit: " إرسال ",
    confirm: " تأكيد ",
    shippingRules: " الشحن والتوصيل ",
    returnRules: " شروط وقواعد الإرجاع ",
    downloadText: " قم بتنزيل تطبيق Vancheen لاكتشاف المزيد ",
    openShop: "أفتح متجري",
    viewShop: "مشاهدة المزيد",
    videoError:
      "مقطع الفيديو هذا غير متوفر حاليا,الرجاء المحاولة لاحقا",
    nextworkError: "خطأ في الشبكة"
  },
  auth: {
    login: "تسجيل الدخول",
    logInSuccess: "تم تسجيل الدخول بنجاح",
    register: "تسجيل ",
    setAccountInfo: " تعيين معلومات الحساب ",
    forgetPassword: "نسيت كلمة السر",
    validEmail: " التحقق من صحة عنوان البريد الإلكتروني ",
    setNewPassword: "تعيين كلمة سر جديدة",
    finishPasswrod: "إنتهاء",
    forgottenPassword: "هل نسيت كلمة السر؟",
    sendAuthCode: "رمز البريد الإلكتروني",
    next: "التالي",
    noAccountGoRegister: "ليس لديك حساب؟ سجل الأن",
    existingAccountToLogin: "لديك حساب بالفعل؟ سجل الدخول الأن",
    placeholderEmail: " إدخال البريد الإلكتروني ",
    placeholderPassword: " إدخال كلمة المرور ",
    placeholderNewPassword: "الرجاء إدخال كلمة السر الجديدة",
    placeholderAuthPassword: "الرجاء إعادة الإدخال ",
    placeholderEmailCode: "إدخال الرمز",
    placeholderNickname: "أدخل إسم المستخدم",
    errorEmail: " خطأ في عنوان البريد الإلكتروني، حاول مرة أخرى ",
    errorEmailNotExist: "الحساب غير موجود",
    errorPasswordNotMatch:
      " كلمة المرور الجديدة لا تتطابق مع كلمة مرور التأكيد ",
    passwordValid: " كلمة المرور تكون من 6-20 حرفاً مع مزيج من الأرقام ",
    nicknameValid: " يكون اللقب من 5 إلى 25 حرفاً مع مزيج من الأرقام ",
    agreedVancheenRegulations: "تم الاتفاق على أنظمة فانشين",
    backLogin: "العودة لتسجيل الدخول",
    resetPasswordStep1: "1. التحقق من البريد الإلكتروني المسجل ", //
    resetPasswordStep2: "2. التحقق من الرمز ", //
    resetPasswordStep3: "3. تعيين كلمة مرور جديدة ", //
    resetPasswordStep4: "4.إنتهاء", //
    pleaseEnterEmail: " الرجاء إدخال ",
    verificationCodeInfo: " تم إرسال رمز التحقق ",
    verificationCodeErroe: " خطأ في رمز التحقق أو البريد الإلكتروني ",
    inputEmail: " أدخل عنوان البريد الإلكتروني ",
    username: " اسم المستخدم ",
    email: " البريد الإلكتروني ",
    pasTitle: " رمز التعريف الشخصي ",
    inputPaw: " أدخل رقم التعريف الشخصي لتسجيل الدخول ",
    pawInfo: "6-20 حرفاً بحرف مزيج الأرقام.",
    registerSuccess: " تم التسجيل بنجاح ",
    correctError: " الرجاء إدخال كلمة المرور الصحيحة أولاً ",
    registerInfo: " سجل بنجاح وقم بتنزيل التطبيق ",
    editName: " تعديل الاسم المستعار ",
    inputNewName: " اسم جديد ",
    inputYourName: " إدخال اسم جديد ",
    tick: " يُرجى وضع علامة ",
    rulesAndProtocols:
      " وافقت على اتفاقية خدمة المستخدم الفردي وسياسة الخصوصية ",
    inputInviteCode: " يُرجى إدخال رمز الدعوة (اختياري)",
    inviteCode: " رمز الدعوة "
  },
  messages: {
    messageCenter: " مركز الرسائل ",
    isTop: " أعلى ",
    unread: " غير مقروءة "
  },
  order: {
    title: " الطلب ",
    selectAddress: " حدد عنوان التوصيل ",
    shippingMethod: " طريقة التوصيل:",
    freightAmount: " رسوم التوصيل ",
    firstWeight(val) {
      return "الأول " + val + "KG：";
    },
    secondWeight(val) {
      return " لكل " + val + "KG：";
    },
    total(val) {
      return " الإجمالي  " + val + " العنصر ";
    },
    priceDetail: " تفاصيل المبلغ ",
    shippingIncluded: " تشمل ",
    coupon: " القسيمة ",
    totalFreight: " مبلغ الشحن ",
    discountAmount: " قيمة الخصم ",
    productTotal: " مجموع المنتجات ",
    allTotal: " إجمالي ",
    submitOrder: " تم الأمر ",
    placeholderSelectAddressFirst: " يُرجى تحديد العنوان أولاً ",
    placeholderSelectAddress: " اختر عنوان الشحن ",
    placeholderSelectFreight: " يُرجى اختيار طريقة التوصيل ",
    placeholderSelectEmpty:
      " لا توجد طريقة شحن في هذا البلد، يُرجى تغيير العنوان ",
    stateAll: " جميع طلبات الشراء ",
    stateUnpay: " قيد الانتظار ",
    stateWaitSending: " تم التوصيل ",
    stateReceiving: " تم الاستلام ",
    stateFinish: " مكتمل ",
    noMore: " لا مزيد.",
    logistics: " تتبّع ",
    delete: " حذف ",
    confirm: " تأكيد الطلب ",
    ordersTtitleInfo: " المبلغ الإجمالي للعنصر (بما في ذلك الشحن):$"
  },
  alert: {
    cancelButtonText: " إلغاء ",
    confirmButtonText: " تأكيد ",
    orderConfirmText: " هل أنت متأكد من تأكيد الطلب؟",
    orderCancelText: " هل أنت متأكد من إلغاء الطلب؟",
    cancellationText: "هل أنت متأكد من تعطيل الحساب؟",
  },
  pay: {
    title: " الدفع ",
    offlinePay: " الدفع دون اتصال بالإنترنت ",
    cardPay: " الدفع ببطاقة الائتمان عبر الإنترنت ",
    lianlianPay: "دفعات Lian lian ",
    backHome: " الصفحة الرئيسية ",
    detail: " تفاصيل طلب الشراء ",
    success: " نجاح عملية الدفع ",
    failure: " فشل عملية الدفع ",
    payType: " اختر طريقة الدفع ",
    onPay: " ادفع الآن ",
    payDetail: " تفاصيل الدفع ",
    rePayDetail: " الدفع مجدداً ",
    cancelOrder: " إلغاء الطلب ",
    emptyPay: " لا توجد طريقة دفع متاحة ",
    selectPay: " يُرجى تحديد طريقة الدفع "
  },
  paymentInfo: {
    optional: " اختياري ",
    required: " مطلوب",
    haveAuth: " تم التحقق بنجاح ",
    unAuth: " لم يتم التحقق ",
    onAuth: " يتم التحقق ",
    reAuth: " فشلت عملية التحقق ",
    passPort: " رقم القيادة/رقم جواز السفر ",
    inputPassPort: " إدخال رقم القيادة/رقم جواز السفر ",
    auth: " المصادقة ",
    nameAuth: " المصادقة ",
    checkAuth: " تحقق ",
    goAuth: " الذهاب للتحقق ",
    title: " يُرجى تحميل لقطة شاشة لقسيمة الدفع ",
    yourName: " إسمك ",
    yourNamePlc: " إسمك ",
    bankName: " اسم البنك ",
    bankNamePlc: " يرجى إدخال اسم حسابك المصرفي.",
    bankAccount: " الحساب المصرفي ",
    bankAccountPlc: "رقم",
    iBankNo: " رقم البنك الدولي.",
    iBankNoPlc: " يُرجى إدخال رقم البنك الدولي الخاص بك.",
    swiftCode: " رمز سويفت كود ",
    swiftCodePlc: " رمز سويفت كود ",
    bankAddress: " عنوان البنك ",
    bankAddressPlc: " الرجاء إدخال عنوان البنك الخاص بحسابك المصرفي.",
    yourPostAdd: " إضافة منشورك ",
    yourPostAddPlc: " إضافة منشورك ",
    done: "تم",
    resubmit: " إعادة الإرسال ",
    reason: " السبب ",
    authMessage:
      " لم تقم بالتحقق من معلومات هويتك، يرجى التوجه للتحقق من هويتك واستكمال المعلومات ",
    accountName: " إسم الحساب ",
    accountNumber: " رقم الحساب ",
    upload: " التحميل ",
    verifying: " يتم التحقق ",
    verifyFailed: " لم ينجح ",
    verifySuccess: " تم اجتيازه ",
    verifyInfo:
      " يُرجى التحويل إلى الحساب المصرفي أدناه، وتعبئة معلومات حساب الدفع وتقديم لقطة شاشة لقسيمة التحويل المصرفي "
  },
  cart: {
    title: " سلة التسوق ",
    total(val) {
      return " مجموع " + val + " العناصر ";
    },
    manage: " إدارة ",
    finish: "تم",
    checkAll: "الكل",
    excludeFreight: " بدون شحن ",
    payMoney: " الإجمالي:",
    delete: " حذف ",
    clearing: " الدفع ",
    recommend: " يوصى به ",
    selectItems: " يُرجى اختيار المنتج ",
    empty: " سلة التسوق فارغة ",
    gogogo: " اذهب في نزهة ",
    productOff: " هذا المنتج غير متوفر ",
    productsOff: " هذه المنتجات غير متوفرة ",
    inventory: " المخزون ",
    inventoryState1: " تحت المخزون ",
    inventoryState2: " غير متوفر من المخزون ",
  },
  errorParamGoBack: " خطأ في المعالجة، يرجى العودة إلى الصفحة السابقة ",
  unselected: " غير محددة ",
  address: {
    pleaseEnter: " يُرجى الإدخال ",
    name: " الإسم ",
    address: " العنوان التفصيلي ",
    phone: " رقم الاتصال ",
    postcode: " الرمز البريدي ",
    addAddress: "+ عنوان التوصيل ",
    country: " الموقع ",
    city: " المدينة ",
    consigneeName: " المرسل إليه ",
    setDefaultAddress: " تعيين كعنوان افتراضي ",
    select: " يرجى الاختيار "
  },
  editAddress: {
    done: " تم ",
    delete: " حذف "
  },
  second: "S",
  name: " الإسم ",
  tel: " الهاتف ",
  save: " حفظ ",
  confirm: " تأكيد ",
  cancel: " إلغاء ",
  delete: " حذف ",
  complete: " مكتمل ",
  loading: " جاري التحميل...",
  telEmpty: " يرجى تعبئة الهاتف ",
  nameEmpty: " الرجاء تعبئة الاسم ",
  confirmDelete: " هل أنت متأكد من رغبتك في الحذف؟",
  telInvalid: " رقم هاتف مشوه ",
  forwardToEarn: " اكسب ",
  // homelist国际化
  homeList: {
    Sold: "تم البيع"
  },
  homeIcon: {
    newProduct: "جديد",
    ladies: "سيدات",
    digital: " رقمي ",
    hotSale: "تنزيلات ساخنة"
  },
  up: "أعلى",
  sell: "مباعة",
  piece: "قطعة",
  noMoreData: " المزيد من الأشياء الرائعة قادمة قريباً!",
  noDetails: " لا توجد تفاصيل ",
  supplier: "مزود",
  in: "في",
  addToStore: " إضافة إلى المتجر ",
  forwardGoods: " السلع الآجلة ",
  belongToSupplier: " أنت موزع منتظم للمورّدين ",
  notAreToSupplier: " أنت لست موزعًا عاديًا للموردين ",
  attention: " انتباه ",
  operateSuccessfully: "تمت العملية بنجاح",
  forward: " إلى الأمام ",
  todayTurnover: " معدل دوران اليوم ",
  todayOrder: " طلب اليوم ",
  visitorsToday: " زوار اليوم ",
  earnings: " المكاسب",
  commodity: " السلع ",
  indent: " المسافة البادئة ",
  operatingData: " بيانات التشغيل ",
  tutorials: " دروس تعليمية ",
  soldOut: " تم البيع ",
  sold: " بيعت ",
  putaway: " ضع جانباً ",
  put: " ضع ",
  all: "الكل",
  sellingPrice: " سعر البيع ",
  sales: " المبيعات ",
  earn: " اربح ",
  interpret: " الترجمة الفورية ",
  unstick: " فك التثبيت ",
  stick: "تثبيت",
  updatePrice: " تحديد السعر ",
  mainHeading: " العنوان الرئيسي ",
  subheading: " العنوان الفرعي ",
  specification: " المواصفات ",
  cost: " التكلفة ",
  profit: " الربح ",
  msetKeyValue: " قيمة مفتاح Mset ",
  discount: " خصم ",
  // 用户管理
  userManage: {
    allOrders: " المزيد من الطلبات ",
    notDeliverGoods: " عدم توصيل البضائع ",
    confiscatedGoods: " تم الاستلام ",
    haveFinished: " انتهت ",
    allGoods: " جميع البضائع ",
    attentionGoods: " الإعجابات والمقتنيات ",
    attentionShop: " متابعة المتاجر ",
    browsingHistory: " مراجعة ",
    coupon: "قسيمة شرائية",
    waitGoods: " تم التوصيل ",
    waitPayment: " قيد الانتظار ",
    haveGoods: " مكتمل ",
    myOrder: " طلب الشراء الخاص بي "
  },
  couponInterpret: {
    state1: "غير مستخدم",
    state2: " تم استخدامها ",
    state3: " غير متاح ",
    state4: " غير متاح ",
    goUse: " استخدام ",
    endDate: " وقت النضج ",
    noCondition: " لا يوجد حد أدنى ",
    condition(value) {
      return " استخدام ما يصل إلى " + value;
    },
    totalCount(value) {
      return value + " قسائم شرائية ";
    },
    couponCount(value) {
      return "¥ " + value + " قسيمة شرائية ";
    },
    alertTitle: " لقد تلقيت هذه القسيمة ",
    selectionTitle: " يُرجى اختيار القسيمة ",
    selectedCoupon: " القسيمة المختارة ",
    discountTotal: " إجمالي الخصم ",
    canuseTitle: " القسائم المتاحة ",
    notuseTitle: " القسائم غير متوفرة ",
    notuseCoupon: " عدم استخدام القسيمة ",
    reason: " سبب هذا الطلب غير متوفر: لا يستوفي شروط الاستخدام "
  },
  support: {
    title: " الدعم الفني ",
    dateHour: " ساعات العمل : 9:00-18:00 (GMT +8)",
    dateWeek: " أيام العمل : الإثنين – الجمعة ",
    helpCenter: " اتصل بنا ",
    email: " البريد الإلكتروني ",
    select: " يُرجى اختيار الخدمة ",
    supportOption1: " الشكاوي والاقتراحات ",
    supportOption2: " أخرى ",
    enterType: " يُرجى اختيار النوع ",
    enterSupport: " يُرجى إدخال المحتوى ",
    enterEmail: " يُرجى إدخال عنوان البريد الإلكتروني ",
    contactWay: " معلومات الاتصال الخاصة بك ",
    supportList: " عرض المحتوى المقدم ",
    serviceType: " نوع الخدمة ",
    serviceTitle: " عنوان السؤال ",
    serviceStatus: " حالة المشكلة ",
    serviceTime: " وقت التقديم ",
    serviceDes: " وصف المشكلة ",
    serviceContact: " معلومات الاتصال ",
    servicePosted: " نُشر بواسطة ",
    serviceContent: " محتوى الرد ",
    serviceReplyTime: " وقت الاستجابة ",
    serviceReplyContent: " محتوى الرد ",
    serviceInputReplyContent: " يُرجى إدخال محتوى الرد ",
    copyRight: " حقوق النسخ ",
    currentVersion: "الإصدار الحالي",
    updateVersion: "تحديث الإصدار"
  },
  goodsAttention: {
    sellingPrice: " السعر "
  },
  orderDetails: {
    logisticsStatus: " تفاصيل التتبع ",
    consignee: " المرسل إليه ",
    shippingAddress: " عنوان التسليم ",
    productTotalPrice: " مبلغ الصنف ",
    freight: " رسوم التوصيل ",
    actualPayment: " الدفع الفعلي ",
    total: " الإجمالي ",
    contactTheShop: " الاتصال بالمورد ",
    orderNumber: " رقم الطلب ",
    logisticsNumber: " رقم التتبع اللوجستي ",
    orderTime: " وقت الطلب ",
    timeOfPayment: " وقت الدفع ",
    payment: " مدفوعة الأجر ",
    product: " المنتج ",
    totalPrice: " السعر الإجمالي ",
    supplier: " المورّد ",
    unpayText(time) {
      return (
        " يُرجى الدفع في غضون " +
        time +
        ", سيتم إلغاء الطلبات المتأخرة تلقائيًا "
      );
    },
    ordersDelivery(delivery) {
      return "( بما في ذلك إجمالي الشحن: $" + delivery + ")";
    },
    totalAmount: " المبلغ الإجمالي للعنصر:"
  },
  settings: {
    userTerms: " شروط المستخدم ",
    userRules: " قواعد المنصة ",
    aboutUs: " نبذة عنا ",
    profilePhoto: " صورة الخلفية ",
    nickname: " اسمي المستعار ",
    myShippingAddress: " عنوان التوصيل ",
    accountDelete: "إلغاء الحساب",
    accountDeleteSuccess: "تم إلغاء تنشيط الحساب بنجاح",
    logOut: " تسجيل الخروج ",
    label1: "مسائل تعديل المعلومات",
    label2: "قضايا تجربة التسوق",
    label3: "قضايا أخرى",
    issue1: "غير قادر على تغيير اسم الحساب",
    issue2: "مسائل الائتمان عبر الهاتف المحمول",
    issue3: "تغيير البريد الإلكتروني",
    issue4: "حساب مكرر",
    issue5: "غير راضٍ عن تسوية المنازعات المتعلقة بالمعاملات",
    issue6: "مشكلات في الدفع تمت مصادفتها",
    issue7: "المخاوف المتعلقة بالأمان والخصوصية",
    issue8: "قضايا أخرى",
    inputText: "الرجاء إدخال مشكلات أخرى",
    nextStep: "الخطوة التالية",
    error: "الرجاء تحديد مشكلة",
    termsText: "لقد قرأت 《التذكيرات الهامة لإلغاء الحساب》 وأوافق عليها"
  },
  deliveryAddress: {},
  searchInfo: {
    synthesis: " شامل ",
    fixture: " طلب شراء الصفقة ",
    intransit: " السعر "
  },
  // 详情与规格
  detailsInterpret: {
    productInfo: " تفاصيل المنتج ",
    weight: " الوزن ",
    bulk: " الحجم ",
    noInformationAtPresent: " لا توجد بيانات وصفية ",
    buy: " اشتري الآن ",
    addcart: " إضافة إلى سلة التسوق ",
    quantity: " الكمية ",
    pleaseSelect: " اختر المنشأ ",
    submit: " إرسال ",
    detailsSold: " مباع ",
    inventory: " المخزون ",
    inventoryText: " هذا المنتج غير متوفر في المخزون ",
    inventoryLimit: " الكمية تزيد عن المخزون ",
    productDescription: " وصف المنتج ",
    none: " لا يوجد ",
    upSold: number => {
      return " لا يمكن أن تكون الكمية أقل من " + number;
    }
  },
  // c注册b
  authenticationInfo: {
    haveAuth: " تم التحقق بنجاح ",
    unAuth: " لم يتم التحقق ",
    onAuth: " يتم التحقق ",
    reAuth: " فشلت عملية التحقق ",
    passPort: " رقم رخصة القيادة / رقم جواز السفر.",
    inputPassPort: " إدخال رقم رخصة القيادة / رقم جواز السفر.",
    auth: " المصادقة ",
    nameAuth: "المصادقة ",
    checkAuth: " تسجيل المتجر ",
    goAuth: " الذهاب للتحقق ",
    title:
      " يُرجى تحميل صورة واضحة لجواز سفرك أو رخصة القيادة （الوجه الأمامي والخلفي）",
    authState: "حالة التحقق",
    yourName: " الإسم الحقيقي ",
    yourNamePlc: " إسمك ",
    bankName: " إسم البنك ",
    bankNamePlc: " يُرجى إدخال اسم البنك الخاص بحسابك.",
    bankAccount: " الحساب البنكي ",
    bankAccountPlc: "رقم",
    iBankNo: " رقم البنك الدولي",
    iBankNoPlc: " يرجى إدخال رقم البنك الدولي الخاص بك",
    swiftCode: " رمز سويفت كود ",
    swiftCodePlc: " رمز سويفت كود ",
    bankAddress: " عنوان البنك ",
    bankAddressPlc: " يُرجى إدخال عنوان البنك الخاص بحسابك.",
    yourPostAdd: " العنوان البريدي ",
    yourPostAddPlc: " العنوان البريدي ",
    done: "تم",
    reason: " السبب ",
    authMessage:
      " لم تكمل التحقق من الاسم الحقيقي، يرجى الانتقال لإكمال التحقق ",
    submitMessage:
      " التحقق من هويتك قيد المراجعة، يرجى الانتظار بصبر ",
    failMessage:
      " فشلت عملية التحقق من هويتك، يرجى إعادة التحقق من هويتك ",
    shopName: " اسم المتجر ",
    yourShopName: " يرجى إدخال اسم المتجر الخاص بك "
  },
  todayData: " بيانات اليوم ",
  turnover: " معدل الدوران ",
  netIncome: " صافي الدخل ",
  orderQuantity: " كمية الطلبات ",
  visitor: " زائر ",
  historicalData: " البيانات السابقة ",
  historySearch: " سجل البحث ",
  cumulativeTurnover: " معدل الدوران التراكمي ",
  cumulativeNetIncome: " صافي الدخل التراكمي ",
  cumulativeOrder: " الترتيب التراكمي ",
  cumulativeVisitors: " عدد الزوار التراكمي ",
  pleaseEnterContent: " يُرجى إدخال المحتوى ",
  waitPayment: " انتظار الدفع ",
  unfollow: " إلغاء المتابعة ",
  cancelCollection: " إلغاء التحصيل ",
  copy: "انسخ",
  copySuccess: " نجاح النسخ ",
  nonsupportCopy: " نسخة غير مدعومة ",
  specialOffer: " عرض خاص ",
  cStore: {
    hotSale: " تخفيضات ساخنة ",
    allProducts: " جميع المنتجات ",
    sold: " بيعت ",
    pleaseEnterContent: " يُرجى إدخال المحتوى "
  },
  plugins: {
    unavailable: " تتطلب هذه الميزة الوصول إلى العميل لاستخدامها ",
    download: " اذهب للتحميل "
  }
};
export default messages;
